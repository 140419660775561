import type { ReactNode } from 'react';

import type { Toast } from 'react-hot-toast';

import sendToast from 'react-hot-toast';
import { dataDogUtil } from 'utils/dataDog.util';

import Message from './Message';
import { ToastMessageType } from './Message/Message';
import ToasterErrorBoundary from './ToasterErrorBoundary/ToasterErrorBoundary';

const getDuration = (type: ToastMessageType): number => [ToastMessageType.error, ToastMessageType.warning].includes(type) ? 300000 : 6000;

const special = (title: string, message: string, color?: string | Array<string>, icon?: ReactNode): void => {
  const duration = getDuration(ToastMessageType.info);

  sendToast((t: Toast) => (
    <Message t={t} type={ToastMessageType.info} title={title} description={message} duration={duration} color={color} icon={icon} />
  ), { duration });
};

const info = (title: string, message: string): void => {
  const duration = getDuration(ToastMessageType.info);

  sendToast((t: Toast) => (
    <Message t={t} type={ToastMessageType.info} title={title} description={message} duration={duration} />
  ), { duration });
};

const success = (title: string, message: string): void => {
  const duration = getDuration(ToastMessageType.success);

  sendToast((t: Toast) => (
    <Message t={t} type={ToastMessageType.success} title={title} description={message} duration={duration} />
  ), { duration });
};

const successWithUndo = async (
  title: string,
  message: string,
): Promise<void> => new Promise((handleResolve, handleReject) => {
  const duration = getDuration(ToastMessageType.success);

  sendToast((t: Toast) => (
    <Message
      t={t}
      type={ToastMessageType.success}
      title={title}
      description={message}
      duration={duration}
      showUndo
      onDismiss={handleResolve}
      onUndo={handleReject}
    />
  ), { duration });
}
);

const warn = (title: string, message: string, action?: string): void => {
  const duration = getDuration(ToastMessageType.warning);
  dataDogUtil.warn(title, message);

  sendToast((t: Toast) => (
    <Message t={t} type={ToastMessageType.warning} title={title} description={message} duration={duration} action={action} />
  ), { duration });
};

const error = (title: string, message: string): void => {
  const duration = getDuration(ToastMessageType.error);
  dataDogUtil.error(title, message);

  sendToast((t: Toast) => (
    <Message t={t} type={ToastMessageType.error} title={title} description={message} duration={duration} />
  ), { duration });
};

export const notificationsUtil = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ToasterErrorBoundary,
  special,
  info,
  error,
  success,
  successWithUndo,
  warn,
};
