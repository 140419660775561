/* eslint-disable @typescript-eslint/naming-convention */
import type { MyProfile } from '../typings/me/MyProfile';
import type { MyPresence } from '../typings/msGraph/MyPresence';
import type { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios';

import axios from 'axios';

const getMyProfile = async (
  idToken: string,
  userEmail: string,
): Promise<MyProfile | undefined> => {
  const url = `https://graph.microsoft.com/v1.0/users/${userEmail}`;
  // eslint-disable-next-line no-console
  // console.log('idToken:', idToken);
  const config = {
    headers: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Authorization: `Bearer ${idToken}`,
    },
  };
  return axios
    .get(url, config)
    .then((response: AxiosResponse): MyProfile => response.data)
    .catch((error: AxiosError): undefined => {
      console.error(error);
      return undefined;
    });
};

const getMyPresence = async (
  accessToken: string,
): Promise<MyPresence | undefined> => {
  const url = 'https://graph.microsoft.com/v1.0/me/presence';
  const config = {
    headers: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios
    .get(url, config)
    .then((response: AxiosResponse): MyPresence => response.data)
    .catch((error: AxiosError): undefined => {
      console.error(error);
      return undefined;
    });
};

const getMyPictureUrl = async (
  accessToken: string,
  size?: 48 | 64 | 96 | 120 | 240 | 360 | 432 | 504 | 648,
): Promise<string | undefined> => {
  const photoTarget = size ? `photos/${size}x${size}` : 'photo';
  const url = `https://graph.microsoft.com/v1.0/me/${photoTarget}/$value`;
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    responseType: 'blob',
  };

  return axios
    .get(url, config)
    .then((response: AxiosResponse | undefined) => {
      if (!response) {
        return;
      }
      return (window.URL || window.webkitURL).createObjectURL(response.data);
    })
    .catch((error: AxiosError): undefined => {
      console.error(error);
      return undefined;
    });
};

export const msGraphService = {
  getMyProfile,
  getMyPictureUrl,
  getMyPresence,
};
