/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from 'react';

import type { BrandData } from 'components/TableComponent/TableComponent';

import { CrossIcon } from '@bestseller/bestone-buying-icon-library';
import axios from 'axios';

import { useAuthentication } from '@bestseller-bit/frontend-community.utilities.authentication';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, styled, TextField, Typography } from '@mui/material';
import { useProfiles } from 'hooks/useProfiles';
import { origin } from 'utils/origin.utils';

interface CreateCertificateDialogProps {
  open: boolean;
  onClose: () => void;
  successMessage: string;
  onCertificateCreated: () => void;
}

interface CertificateData {
  styleNumber: number;
  brandName: string;
  ceDocument: string;
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export const CreateCertificateDialog = ({ open, onClose, successMessage: initialSuccessMessage, onCertificateCreated }: CreateCertificateDialogProps) => {

  const { buyingProfile } = useProfiles();
  const [file, setFile] = useState<File | null>(null);
  const [brands, setBrands] = useState<Array<BrandData>>([]);
  const [successMessage, setSuccessMessage] = useState<string | null>(initialSuccessMessage);
  const { acquireIdToken } = useAuthentication();

  const [certificateData, setCertificateData] = useState<CertificateData>({
    styleNumber: 0,
    brandName: '',
    ceDocument: '',
  });

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const response = await axios.get(`${origin}/safety-certificate/public/brand/brands`);
        setBrands(response.data.brands); // Update to use response.data.brands
      } catch (error) {
        console.error('Error fetching brands:', error);
      }
    };

    fetchBrands();
  }, []);

  const handleCloseDialog = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleCancel = () => {
    // Reset the form fields and file state
    setCertificateData({
      styleNumber: 0,
      brandName: '',
      ceDocument: '',
    });
    setFile(null);
    handleCloseDialog();
  };

  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;

    if (name === 'styleNumber' && value.length === 8) {
      // Enable brand and CE document inputs when the style number has 8 digits
      setCertificateData((prevData) => ({ ...prevData, [name]: parseInt(value, 10) || 0, brandName: '', ceDocument: '' }));
    } else {
      // Disable brand and CE document inputs if style number is not 8 digits
      setCertificateData((prevData) => ({ ...prevData, [name]: name === 'styleNumber' ? parseInt(value, 10) || 0 : value }));
    }
  };

  const handleFileSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFile = event.target.files?.[0];

    if (newFile) {
      // Set the selected file when a file is chosen
      setFile(newFile);

      // Update the ceDocument in the certificateData state with the new file name
      setCertificateData((prevData) => ({
        ...prevData,
        ceDocument: newFile.name, // Use the file name as ceDocument
      }));
    } else {
      // Reset to null if no file is chosen (optional)
      setFile(null);

      // Reset ceDocument when no file is chosen
      setCertificateData((prevData) => ({
        ...prevData,
        ceDocument: '',
      }));
    }
  };

  const handleSave = async () => {
    try {
      const token = await acquireIdToken();
      const formData = new FormData();
      if (file) {
        formData.append('file', file);
      }

      formData.append('styleNumber', certificateData.styleNumber.toString());
      formData.append('isDeleted', 'false');
      formData.append('createdBy', buyingProfile?.userNumber ?? '');
      formData.append('changedBy', buyingProfile?.userNumber ?? '');

      const response = await axios.post(
        `${origin}/safety-certificate/private/certificate/`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization : 'Bearer ' + token,
          },
        }
      );
      // Set success message and clear it after a few seconds
      setSuccessMessage('Created successfully!');
      setTimeout(() => {
        setSuccessMessage(null);
      }, 2000);
      setCertificateData({
        styleNumber: 0,
        brandName: '',
        ceDocument: '',
      });
      setFile(null);
      onCertificateCreated();
      handleCloseDialog();
    } catch (error) {
      console.error('Error creating certificate:', error);

      if (axios.isAxiosError(error)) {
        console.error('Axios error details:', {
          config: error.config,
          response: error.response,
        });
      }
    }
  };

  return (
    <>
      <Dialog open={open} maxWidth="md" fullWidth>
        <DialogTitle textAlign="center" sx={{ padding: 5 }}>
          <Typography variant='headings.h4'>
            Create a new certificate
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ padding: 5 }}>
          <TextField
            label="Style Number"
            name="styleNumber"
            value={certificateData.styleNumber === 0 ? '' : certificateData.styleNumber}
            placeholder="Type a full style number with 8 digits"
            margin="normal"
            type="text"
            inputProps={{
              maxLength: 8,
              inputMode: 'numeric',
            }}
            fullWidth
            onChange={handleTextFieldChange}
          />
          <TextField
            label="CE Document"
            name="ceDocument"
            value={certificateData.ceDocument}
            margin="normal"
            disabled={certificateData.styleNumber.toString().length !== 8}
            InputProps={{
            readOnly: true,
            disableUnderline: true,
            }}
            placeholder=""
            fullWidth
          />
          <input
            type="file"
            accept=".pdf"
            style={{ display: 'none' }}
            onChange={handleFileSelection}
          />
        </DialogContent>
        <DialogActions sx={{ padding:'0px', paddingRight:5, paddingBottom:5, justifyContent: 'space-between' }}>
          <Button size='small' sx={{ marginLeft:5 }} onClick={handleCancel}>
            <CrossIcon style={{ padding: '0px 12px 0px 0px', width:'14px', height:'14px' }} />
            Cancel
          </Button>
          <Box>
            <Button
              component='label'
              variant="contained"
              startIcon={<CloudUploadIcon style={{ padding: '0px 12px 0px 0px', width:'18px', height:'18px' }} />}
              size='small'
              disabled={!certificateData.styleNumber}
            >
              Browse a file
              <VisuallyHiddenInput type="file" accept=".pdf" onChange={handleFileSelection} />
            </Button>
            <Button
              variant="contained"
              sx={{ marginLeft: 5 }}
              size='small'
              disabled={!certificateData.styleNumber || !file}
              onClick={(): void => void handleSave()}
            >
              Save
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      {successMessage && (
        <Snackbar
          sx={{ height: '100%' }}
          anchorOrigin={{
           vertical: 'top',
           horizontal: 'center',
          }}
          open
          onClose={() => void setSuccessMessage(null)}
        >
          <Alert
            severity="success"
            sx={{
              width: '180px',
              minHeight: '104px',
              alignItems: 'center',
              fontSize: '14px',
            }}
          >
            {successMessage}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};
