import type { ElementRef, ReactElement, ChangeEvent } from 'react';
import { useRef, useState } from 'react';

import { CrossIcon, PlusIcon, SearchIcon } from '@bestseller/bestone-buying-icon-library';

import { useIsAuthenticated } from '@azure/msal-react';
import { useAuthentication } from '@bestseller-bit/frontend-community.utilities.authentication';
import { Box, TextField, Typography, useTheme, Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { AboutButton } from 'components/About button/AboutButton';
import { CreateCertificateDialog } from 'components/CreateCertificateDialog/CreateCertificateDialog';
import { UserInfo } from 'components/UserInfo/UserInfo';

const innerInputId = 'search-bar-inner-input';
interface HeaderProps {
  styleNumber: string;
  setStyleNumber: React.Dispatch<React.SetStateAction<string>>;
  generalQuery: string;
  setGeneralQuery: React.Dispatch<React.SetStateAction<string>>;
  onSearchClear: () => void;
  successMessage: string;
  onCertificateCreated: () => void;
}
export const Header = ({ setStyleNumber, onSearchClear, successMessage, onCertificateCreated }: HeaderProps): ReactElement => {
  const [inputValue, setInputValue] = useState<string>('');
  const [hasFocus, setHasFocus] = useState<boolean>(false);
  const inputRef = useRef<ElementRef<'input'>>(null);
  const { palette } = useTheme();

  const [openCreateCertificateDialog, setOpenCreateCertificateDialog] = useState(false);
  const isAuthenticated = useIsAuthenticated();
  const { logout, login } = useAuthentication();

  const iconSize = 24;
  const boxHeightSearchBar = 56;
  const fontFamilySeachBar = 18;
const BUTTON_ICON_SIZE = 16;

const handleInputChange = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
  const value = event.target.value;
  setInputValue(value);

  // Update the parent component's styleNumber state
  setStyleNumber(value);
};

const handleClearInput = (): void => {
  setInputValue('');
  onSearchClear();
};

const handleOnSignIn = (): void => {
  //acquireIdToken();
  if (login) {
    login();
  } else {
    console.error('Login function is undefined');
  }
};

// const handleOnSignIn = (): void => {
//   console.log('Sign in clicked');
// };

const handleSignOut = (): void => {
  logout();
};

const handleCreateCertificate = (): void => {
  setOpenCreateCertificateDialog(true);
  onCertificateCreated();
};

  return (
    <Box
      sx={{
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 10,
        paddingBottom: 3,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: palette.mode === 'dark' ? palette.common.black : palette.common.white,
      }}
    >
      <Box
        alignItems='center'
        justifyContent='center'
        display='flex'
        sx={{
          marginRight: '20px',
        }}
      >
        <Typography variant='headings.h1'>BESTSELLER</Typography>
      </Box>
      <Box sx={{ flex: '1', display: 'flex', justifyContent: 'center', marginLeft: '12px' }}>
        <TextField
          variant="outlined"
          placeholder='Search by full style number'
          value={inputValue}
          inputProps={{ maxLength: 8 }} // Set the maximum length to 8
          sx={{
            ['& .MuiInputBase-root']: {
              height: boxHeightSearchBar,
            },
            ['& .MuiOutlinedInput-root']: {
              mt: '0 !important',
              pr: inputValue ? 2 : !hasFocus ? 5 : 0,
            },
            ['& .MuiOutlinedInput-input']: {
              fontSize: fontFamilySeachBar,
            },
          }}
          InputProps={{
            inputProps: {
              id: innerInputId,
              ref: inputRef,
            },
            startAdornment: (
              <InputAdornment position="start" sx={{ mr: 0 }}>
                <SearchIcon color={palette.text.primary} style={{ width: iconSize, height: iconSize }} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {inputValue && (
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  <IconButton onClick={handleClearInput}>
                    <CrossIcon
                      style={{ width: iconSize, height: iconSize }}
                    />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
          fullWidth
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onChange={handleInputChange}
          onFocus={(): void => void setHasFocus(true)}
          onBlur={(): void => void setHasFocus(false)}
        />
      </Box>
      <Box
        sx={{
          flex: '1',
          display: 'flex',
          justifyContent: 'right',
          marginLeft: '10px',
        }}
      >
        <Box
          sx={{
            height: '24px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingLeft: 10,
            flex: 1,
          }}
        >
          {isAuthenticated ? (
            <Box
              sx={{
              display: 'flex',
              alignItems: 'center',
              }}
            >
              <Button
                startIcon={<PlusIcon size={BUTTON_ICON_SIZE} />}
                size='small'
                onClick={handleCreateCertificate}
              >
                Create a new certificate
              </Button>
              <AboutButton />
              <Button key="signOut-button" size="small" sx={{ marginLeft: 4 }} onClick={handleSignOut}>
                Sign Out
              </Button>
              <UserInfo />
            </Box>
        ) : (
          <Box sx={{ display:'flex' }}>
            <Button key="signIn-button" variant="outlined" size="small" sx={{ marginLeft: 4 }} onClick={handleOnSignIn}>
              Sign In
            </Button>
            <AboutButton />
          </Box>
        )}
        </Box>
      </Box>
      {isAuthenticated ? (
        <CreateCertificateDialog
          open={openCreateCertificateDialog}
          successMessage={successMessage}
          onClose={() => void setOpenCreateCertificateDialog(false)}
          onCertificateCreated={onCertificateCreated}
        />
    ) : null}
    </Box>
  );
};
