import React, { useEffect, useState, useCallback } from 'react';

import type { AccountInfo } from '@azure/msal-common';

import axios from 'axios';

import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Typography, Avatar } from '@mui/material';
import { Box } from '@mui/system';
import { useProfiles } from 'hooks/useProfiles';

export const UserInfo = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const [pictureUrl, setPictureUrl] = useState<string | undefined>(undefined);
  const { msalProfile, buyingProfile } = useProfiles();

  const getUsersPictureUrl = useCallback(
    async (account: AccountInfo, size?: 48 | 64 | 96 | 120 | 240 | 360 | 432 | 504 | 648) => {
      const photoTarget = size ? `photos/${size}x${size}` : 'photo';

      try {
        const response = await axios.get(`https://graph.microsoft.com/v1.0/me/${photoTarget}/$value`, {
          headers: {
            Authorization: `Bearer ${
              await instance
                .acquireTokenSilent({
                  account, // No need for type assertion here
                  scopes: ['user.read'], // Adjust the scope as needed
                })
                .then((result) => result.accessToken)
            }`,
          },
          responseType: 'blob',
        });

        if (response) {
          return (window.URL || window.webkitURL).createObjectURL(response.data);
        }
      } catch (error) {
        console.error(error);
      }

      return undefined;
    },
    [instance]
  );

  useEffect(() => {
    const fetchProfilePicture = async (): Promise<void> => {
      try {
        if (!isAuthenticated || !accounts || accounts.length === 0) return;

        const url = await getUsersPictureUrl(accounts[0]);
        setPictureUrl(url);
      } catch (error) {
        console.error('An error occurred while retrieving profile picture:', error);
      }
    };

    fetchProfilePicture();
  }, [isAuthenticated, accounts, getUsersPictureUrl]);

  if (!isAuthenticated) {
    return <p>User not authenticated</p>;
  }

  const userAccount = accounts[0];
  if (!userAccount.name) {
    return <p>User account information not available</p>;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: '40px',

      }}
    >
      <Avatar src={pictureUrl} alt={userAccount.name} />
      <Typography sx={{ paddingLeft:'10px' }} variant='captions.default'>
        Welcome,
        <br />
        {' '}
        <Typography variant='captions.highlight'>
          {userAccount.name}
        </Typography>
        <br />
        <Typography
          variant="captions.default"
        >
          {msalProfile?.jobTitle}
        </Typography>
      </Typography>
    </Box>
  );
};
