import type { ReactElement } from 'react';

import { RefreshIcon } from '@bestseller/bestone-buying-icon-library';

import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';

const PREFIX = 'UndoButton';

const classes = {
  wrapper: `${PREFIX}-wrapper`,
  icon: `${PREFIX}-icon`,
  linearProgress: `${PREFIX}-linearProgress`,
};

const Root = styled(motion.div)({
  [`&.${classes.wrapper}`]: {
    position: 'relative',
    marginRight: '.5rem',
  },
  [`& .${classes.icon}`]: {
    color: 'rgba(0,0,0,.54)',
    marginLeft: '.84rem',
  },
  [`& .${classes.linearProgress}`]: {
    position: 'absolute',
    top: '0',
    width: '100%',
    height: '100%',
    opacity: '0.4',
    borderRadius: '.5rem',
    ['&:hover']: {
      cursor: 'pointer',
    },
  },
});

interface PropStyles {
  timerValue: number;
  onUndo: () => void;
}

export const UndoButton = ({
  timerValue,
  onUndo: handleUndo,
}: PropStyles): ReactElement => (
  <Root
    className={classes.wrapper}
    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 0.9 }}
    onClick={handleUndo}
  >
    <Button variant="contained" color="primary">
      <span>Undo</span>
      <RefreshIcon className={classes.icon} />
    </Button>
    <LinearProgress
      variant="determinate"
      value={timerValue}
      color="secondary"
      className={classes.linearProgress}
    />
  </Root>
);
