/* eslint-disable @typescript-eslint/no-invalid-this */
import { Component } from 'react';
import type { PropsWithChildren, ReactNode } from 'react';

import { notificationsUtil } from '../notifications.util';

interface State {
  error: Error | null;
}

interface PropTypes {
  onError?: (error: Error | null) => void;
}

class ToasterErrorBoundary extends Component<PropsWithChildren<PropTypes>, State> {
  public constructor (props: PropsWithChildren<PropTypes>) {
    super(props);
    this.state = {
      error: null,
    };
  }

  public componentDidCatch = (error: Error | null): void => {
    this.setState({ error });
    notificationsUtil.error(
      error?.name ?? 'Something went wrong',
      error?.message ?? error?.stack ?? 'Something went wrong'
    );
  };

  public render = (): ReactNode => this.props.children;
}

export default ToasterErrorBoundary;
