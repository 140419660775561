// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;

const info = (errorName: string, errorInfo: unknown): void =>
  window.DD_LOGS?.logger.info(errorName, errorInfo);

const error = (errorName: string, errorInfo: unknown): void =>
  window.DD_LOGS?.logger.error(errorName, errorInfo);

const warn = (errorName: string, errorInfo: unknown): void =>
  window.DD_LOGS?.logger.warn(errorName, errorInfo);

const addLoggerGlobalContext = (name: string, value: unknown): void =>
  window.DD_LOGS?.addLoggerGlobalContext(name, value);

export const dataDogUtil = {
  info,
  error,
  warn,
  addLoggerGlobalContext,
};
