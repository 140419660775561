import type { ReactElement } from 'react';

import { CrossIcon } from '@bestseller/bestone-buying-icon-library';

import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

import { CircularProgress } from '../CircularProgress/CircularProgress';

const StyledIconButton = styled(IconButton)({
  minWidth: '42px !important',
  minHeight: '42px !important',
  marginLeft: '1.6rem',
});

interface PropTypes {
  timerValue: number;
  showTimer: boolean;
  onDismiss: () => void;
}

export const DismissButton = ({
  timerValue,
  showTimer = false,
  onDismiss: handleDismiss,
}: PropTypes): ReactElement => {

  const renderButtonContent = showTimer ? (
    <CircularProgress value={timerValue}>
      <CrossIcon />
    </CircularProgress>
  ) : (
    <CrossIcon />
  );

  return (
    <StyledIconButton onClick={handleDismiss}>
      {renderButtonContent}
    </StyledIconButton>
  );
};
