import type { BuyingProfile } from '@bestseller/bestone-buying-ui-library';
import type { AxiosResponse } from 'axios';

import axios from 'axios';

import { mockBuyingProfile } from '@bestseller/bestone-buying-ui-library';
import { URLS } from 'constants/urls';
import { notificationsUtil } from 'notifications/notifications.util';
import { historyUtil } from 'utils/history.utils';

const storeUser = (payload: BuyingProfile): void => {
  const user = JSON.stringify(payload);
  if (localStorage.getItem('tokens')) {
    localStorage.setItem('user', user);
  } else {
    sessionStorage.setItem('user', user);
  }
};

const removeUser = (): void => {
  localStorage.removeItem('user');
  sessionStorage.removeItem('user');
};

const getUser = async (
  idToken: string,
): Promise<BuyingProfile | undefined> => {
  const url = `${URLS.backendUrl}/user-api/loggedInUser`;
  const options = {
    headers: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Authorization: `Bearer ${idToken}`,
      provider: 'AD',
    },
  };

  return axios
    .get<BuyingProfile>(url, options)
    .then((response: AxiosResponse<BuyingProfile>): BuyingProfile => {
      storeUser(response.data);
      return response.data;
    })
    .catch((e) => {
      console.error('userService.getUser:', e);
      if (location.hostname === 'localhost') {
        const fakeUser: BuyingProfile = mockBuyingProfile();
        notificationsUtil.error(
          `I can't get your profile data!`,
          `Be aware that I will use fake profile data instead (only on localhost).
          My apologies for the inconvenience!`
        );
        storeUser(fakeUser);
        return fakeUser;
      } else {
        historyUtil.history.push({
          pathname: '/failed-login',
        });
      }
      return undefined;
    });
};

export const userService = {
  getUser,
  removeUser,
};
