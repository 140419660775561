import React, { useState } from 'react';

import { CrossIcon } from '@bestseller/bestone-buying-icon-library';

import { Box, Button, Dialog, IconButton, DialogContent, Typography, DialogActions, useTheme } from '@mui/material';

export const AboutButton = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const theme = useTheme();

  const handleShowDialog = (): void => {
    setOpenDialog(true);
  };

const handleCloseDialog = (): void => {
    setOpenDialog(false);
  };

  return (
    <Box>
      <Button key='about-button' size="small" sx={{ marginLeft: 5 }} onClick={handleShowDialog}>about</Button>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={openDialog}
        sx={{
              '& .MuiDialogContent-root': {
                    padding: theme.spacing(10),
              },
        }}
        onClose={handleCloseDialog}
      >
        <IconButton
          aria-label="close"
          sx={{
                position: 'absolute',
                right: 8,
          }}
          onClick={handleCloseDialog}
        >
          <CrossIcon size={20} />
        </IconButton>
        <DialogContent>
          <Typography variant='captions.default' sx={{ textAlign:'justify' }}>
            All products placed on the (European) market have to be legally compliant and safe to use.
            The European Union has developed product specific directives and standards for certain product types, such as Electronics, Toys and Personal Protective Equipment.
            These products must comply with the directive in regards to special safety and must be marked with a CE-marking.
            <br />
            <br />
            Sunglasses are considered to be Personal Protective Equipment, which means that they have to bear a CE-marking and all sunglasses should be accompanied by a Declaration of Conformity.
            If the Declaration of Conformity does not come with the sunglasses in hard copy, a link must be provided to a website where the Declaration of Conformity can be found.
            <br />
            <br />
            This is the website, where Declarations of conformity for [BESTSELLER Brands’] sunglasses can be found.
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
              justifyItems: 'center',
              justifyContent: 'center',
              alignItems: 'center',
          }}
        >
          <Button
            sx={{ marginBottom: 5 }}
            autoFocus
            onClick={handleCloseDialog}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
