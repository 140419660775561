/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from 'react';

import type { CertificateData, BrandData } from 'components/TableComponent/TableComponent';

import { CrossIcon } from '@bestseller/bestone-buying-icon-library';
import axios from 'axios';
import { PDFDocument } from 'pdf-lib';

import { useAuthentication } from '@bestseller-bit/frontend-community.utilities.authentication';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Dialog, DialogContent, DialogActions, Button, styled, TextField, Box, DialogTitle, Typography, Alert, Snackbar } from '@mui/material';
import { useProfiles } from 'hooks/useProfiles';
import { origin } from 'utils/origin.utils';

interface EditDialogProps {
  open: boolean;
  onClose: () => void;
  certificate: CertificateData | null;
  successMessage: string;
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export const EditDialog = ({ open, onClose, certificate, successMessage: initialSuccessMessage }: EditDialogProps) => {
  //state for the new pdf file
const [newPdfFile, setNewPdfFile] = useState<File | null>(null);
//state for the edited style number
const [editedStyleNumber, setEditedStyleNumber] = useState<number | null>(certificate?.styleNumber ?? null);
const [brands, setBrands] = useState<Array<BrandData>>([]);
//state for the selected brand
const [selectedBrand, setSelectedBrand] = useState<string | null>(certificate?.brandNumber ?? null);
//state for the selected file name
const [selectedFileName, setSelectedFileName] = useState<string | null>(null);
//state for the certificates
const [certificates, setCertificates] = useState<Array<CertificateData>>([]);
 // Set the initial brand name based on the brandNumber in the certificate
 const initialBrand = certificate?.brandNumber
 ? brands.find((brand) => brand.brandNumber === certificate.brandNumber)
 : null;
const [initialBrandName, setInitialBrandName] = useState<string | null>(initialBrand?.brandName ?? null);

const { buyingProfile } = useProfiles();
const { acquireIdToken } = useAuthentication();
const [successMessage, setSuccessMessage] = useState<string | null>(initialSuccessMessage);

useEffect(() => {
  // Fetch initial data
  fetchData();
}, []); // Call it only once when the component mounts

//this fetches the data from the backend and updates the rows in the table with the new data
const fetchData = async () => {
  try {
    const response = await axios.get(`${origin}/safety-certificate/public/certificate/certificates`);
    setCertificates(response.data.certificates);
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

useEffect(() => {
  // Fetch brands
  axios
    .get(`${origin}/safety-certificate/public/brand/brands`)
    .then((response) => {
      // console.log('Brands Response:', response.data);
      setBrands(response.data.brands);
    })
    .catch((error) => {
      console.error('Brands Error:', error);
    });
}, []);

//this useEffect is used to update the edited style number & the selected brand  when the certificate changes
useEffect(() => {
  setEditedStyleNumber(certificate?.styleNumber ?? null);
  setSelectedBrand(certificate?.brandNumber ?? null);
}, [certificate]);

const handleCloseDialog = useCallback(
  (event?: {}, reason?: 'backdropClick' | 'escapeKeyDown') => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      // User clicked away from the dialog
      return;
    }
    onClose();
  },
  [onClose]
);

//this function is used to handle the file change
const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const file = event.target.files?.[0] ?? null;
  if (file) {
    console.log('Selected File:', file);
    console.log('File Size:', file.size);
    setNewPdfFile(file);
    setSelectedFileName(file.name); // Set the selected file name when the file changes
  }
};

const handleUpload = async (): Promise<void> => {
  if (!certificate) {
    // Handle validation or show an error message
    return;
  }

  try {
    const token = await acquireIdToken();
    if (!token) {
      console.error('Failed to acquire token');
      return;
    }
    const formData = new FormData();

    // If a new file is passed, read its contents and append it to the FormData
    if (newPdfFile) {
      const pdfBytes = await new Promise<ArrayBuffer | null>((resolve) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          if (event.target) {
            resolve(event.target.result as ArrayBuffer);
          } else {
            resolve(null);
          }
        };
        reader.readAsArrayBuffer(newPdfFile);
      });

      if (!pdfBytes) {
        console.error('Failed to read PDF file');
        return;
      }

      const pdfDoc = await PDFDocument.load(pdfBytes);
      const modifiedPdfBytes = await pdfDoc.save();
      console.log('Modified PDF Size:', modifiedPdfBytes.length);

      // Create a File object and append it to the FormData
      const file = new File([modifiedPdfBytes], newPdfFile.name, { type: 'application/pdf' });
      formData.append('file', file);

      setSelectedFileName(newPdfFile.name);
      //to avoid 500 internal server error when the file is empty
    } else {
      const emptyBlob = new Blob([], { type: 'application/pdf' });
      formData.append('file', emptyBlob, 'empty.pdf');
    }

    // Append other required parameters
    if (editedStyleNumber !== null) {
      formData.append('styleNumber', editedStyleNumber.toString());
    }
    formData.append('isDeleted', 'false');
    formData.append('createdBy', certificate.createdBy.toString());
    formData.append('changedBy', buyingProfile?.oid ?? '');

    // Make the API call
    const response = await axios.put(
      `${origin}/safety-certificate/private/certificate/${certificate.certificateNumber}`,
      formData,
      {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization : 'Bearer ' + token,
        },
      }
    );

    console.log('Upload Success:', response.data);
    fetchData();
    setSuccessMessage('Edited successfully! Please refresh the page to see the changes.');
    // Close the dialog after successful upload
    onClose();
  } catch (error) {
    console.error('Upload Error:', error);
  }
};
// Update the initialBrandName state when the certificate changes
useEffect(() => {
  // Set initialBrandName and selectedBrand to the brandName corresponding to the brandNumber in the certificate
  const updatedBrand = certificate?.brandNumber
    ? brands.find((brand) => brand.brandNumber === certificate.brandNumber)
    : null;
  const updatedBrandName = updatedBrand?.brandName ?? null;
  setInitialBrandName(updatedBrandName);
  setSelectedBrand(updatedBrandName);
}, [certificate, brands]);

return (
  <>
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle textAlign="center" sx={{ padding: 5 }}>
        <Typography variant='headings.h4'>
          Edit Certificate
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ padding: 5 }}>
        {certificate && (
          <Box
            sx={{
              flexDirection: 'column',
              display: 'flex',
            }}
          >
            <TextField
              label="Style Number"
              value={editedStyleNumber ?? ''}
              onChange={(e) => void setEditedStyleNumber(parseInt(e.target.value, 10) || null)}
            />
            <p>
              CE Document:
              {' '}
              <Box>
                <span>{selectedFileName ?? certificate.ceDocumentName}</span>
              </Box>
            </p>
          </Box>
        )}
      </DialogContent>
      <DialogActions sx={{ padding:'0px', paddingRight:5, paddingBottom:5, justifyContent: 'space-between' }}>
        <Button size='small' sx={{ marginLeft:5 }} onClick={handleCloseDialog}>
          <CrossIcon style={{ padding: '0px 12px 0px 0px', width:'14px', height:'14px' }} />
          Cancel
        </Button>
        <Box>
          <Button size='small' component="label" variant="contained" startIcon={<CloudUploadIcon style={{ padding: '0px 12px 0px 0px', width:'18px', height:'18px' }} />} disabled={!editedStyleNumber}>
            Browse a file
            <VisuallyHiddenInput type="file" onChange={handleFileChange} />
          </Button>
          <Button sx={{ marginLeft: 5 }} variant="contained" color="primary" onClick={() => void handleUpload()}>
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
    {successMessage && (
      <Snackbar
        sx={{ height: '100%' }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        autoHideDuration={2000}
        open
        onClose={() => void setSuccessMessage(null)}
      >
        <Alert
          severity="success"
          sx={{
            width: '500px',
            minHeight: '104px',
            alignItems: 'center',
            fontSize: '14px',
            paddingLeft: 10,
          }}
        >
          {successMessage}
        </Alert>
      </Snackbar>
    )}
  </>
);
};
