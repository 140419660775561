import type { AuthenticationResult, Configuration, EventMessage, PopupRequest } from '@azure/msal-browser';

import { PublicClientApplication, EventType } from '@azure/msal-browser';

const branchUrl = new RegExp(/\/BUY-\d{4}/).exec(window.location.pathname)?.[0] ?? '';

const msalConfig: Configuration = {
  auth: {
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_TENANT_ID}`,
    clientId: `${process.env.REACT_APP_AZURE_CLIENT_ID}`,
    redirectUri:  `${window.location.origin}/bs-certificates${branchUrl}/`,
    postLogoutRedirectUri: `${window.location.origin}/bs-certificates${branchUrl}/`,
    navigateToLoginRequestUrl: false, //no automatic login
  },
  cache: {
    cacheLocation: 'sessionStorage',
  },
};

const msalLoginRequest: PopupRequest = {
  scopes: [
    'User.Read',
  ],
  prompt: 'select_account',
};

const msalInstance = new PublicClientApplication(msalConfig);

const accounts = msalInstance.getAllAccounts();

if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

export const msalService = {
  msalInstance,
  msalLoginRequest,
};
