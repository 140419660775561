/* eslint-disable max-lines */
import type { ReactElement, ReactNode } from 'react';
import { useEffect, useMemo, useState } from 'react';

import type { Brand, BuyingProfile, MsalProfile } from '@bestseller/bestone-buying-ui-library';

import {
  GetAllBrandsDocument,
  mockBuyingProfile,
  sortUtil,
  useBasicDataQuery,
} from '@bestseller/bestone-buying-ui-library';
import { useAuthentication } from '@bestseller-bit/frontend-community.utilities.authentication';
import { ProfilesContext } from 'contexts/ProfilesContext';
import { msGraphService } from 'services';
import { userService } from 'services/user.service';
import { historyUtil } from 'utils/history.utils';

interface ProfilesProviderProps {
  children: ReactNode;
}

// NOTE: Most logic in this provider is based on the @azure/msal-react example for typescript
// https://github.com/AzureAD/microsoft-authentication-library-for-js/tree/dev/samples/msal-react-samples/typescript-sample
export const ProfilesProvider = ({ children }: ProfilesProviderProps): ReactElement => {
  const { acquireIdToken, isAuthenticated } = useAuthentication();

  const [msalProfile, setMsalProfile] = useState<MsalProfile | undefined>(undefined);
  const [buyingProfile, setBuyingProfile] = useState<BuyingProfile | undefined>();
  const [msalProfileLoading, setMsalProfileLoading] = useState<boolean>(true);
  const [buyingProfileLoading, setBuyingProfileLoading] = useState<boolean>(true);

  const loading = msalProfileLoading || buyingProfileLoading;

  // Get deleted brands
  const { data: deletedBrands } = useBasicDataQuery<{ brands: Array<Brand> }>(
    GetAllBrandsDocument,
    { variables: { isDeleted: true } }
  );

  const contextValue = useMemo(() => ({
    msalProfile,
    setMsalProfile,
    buyingProfile,
    setBuyingProfile,
    loading,
  }), [msalProfile, buyingProfile, loading]);

  // NOTE: Load msalProfile if none is present
  useEffect(() => {
    if (isAuthenticated && !msalProfile) {
      acquireIdToken()
        .then((token) => {
          // eslint-disable-next-line no-console
          // console.log('token:', token);
          setMsalProfileLoading(true);

          msGraphService
            .getMyProfile(token, buyingProfile?.email || '')
            .then((newMsalProfile: MsalProfile | undefined) => {
              // eslint-disable-next-line no-console
              // console.log('newMsalProfile:', newMsalProfile);
              if (newMsalProfile) {
                setMsalProfile(newMsalProfile);
              }
            })
            .finally(() => {
              setMsalProfileLoading(false);
            });
            });
      }
  }, [acquireIdToken, buyingProfile?.email, isAuthenticated, msalProfile, setMsalProfile]);

  // Load buyingProfile if none is present
  useEffect(() => {
    // If we couldn't log in, but we are on localhost, we mock the buying profile
    if (isAuthenticated && !buyingProfile) {
      acquireIdToken()
        .then((token)=> {
          setBuyingProfileLoading(true);
          userService
            .getUser(token)
            .then((newBuyingUser: BuyingProfile | undefined): void => {
              if (newBuyingUser) {
                // BUY-5231: Until the BE filters out deleted brands from the buying profile,
                // we need to do a manual filter
                const userBrands: Array<Brand> = !deletedBrands?.brands
                  // Without deletedBrands.brands we return the unfilterd user brands
                  ? newBuyingUser.brands
                  // We want to filter out the brands that are in the brand query from the basic-data BFF.
                  // These are deleted, so when the user logs in, they should not be in the brand picker.
                  : newBuyingUser.brands.filter((brand) => !deletedBrands.brands
                    .find((userBrand) => userBrand.brandNumber === brand.brandNumber));

                setBuyingProfile({
                  ...newBuyingUser,
                  // Finally, we sort the brands by brandName alphabetically
                  brands: sortUtil.alphabetize(userBrands, 'brandName'),
                });
              }
            })
            .catch((e: Error): void => {
              console.error('useEffect targetService.getUser:', e);
              historyUtil.history.push('/failed-login');
            })
            .finally(() => {
              setBuyingProfileLoading(false);
            });
          });
    }
  }, [acquireIdToken, buyingProfile, deletedBrands?.brands, isAuthenticated, msalProfile, setBuyingProfile]);

  return (
    <ProfilesContext.Provider value={contextValue}>
      {children}
    </ProfilesContext.Provider>
  );
};
