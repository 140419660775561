import React from 'react';

import { datadogRum } from '@datadog/browser-rum';
import ReactDOM from 'react-dom';

import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';

datadogRum.init({
  applicationId: '',
  clientToken: '',
  site: 'datadoghq.eu',
  service: 'buying-bs-certificates-ui',
  env: '<dev/prod>', //TODO: Generate from deployment
  // version: '1.0.0',
  sampleRate: 100,
  trackInteractions: true,
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
