import type { ReactElement, ReactNode } from 'react';
import { useMemo } from 'react';

import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
  ApolloLink,
  gql,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { GraphClientNames } from '@bestseller/bestone-buying-ui-library';
import { useAuthentication } from '@bestseller-bit/frontend-community.utilities.authentication';
import { URLS } from 'constants/urls';

/** NOTE: Mocking data
 * https://www.apollographql.com/docs/react/development-testing/client-schema-mocking/
 */
const typeDefs = gql`
  extend type StyleConnection {
    count: Int
  }
  extend type Collection {
    createdDate: String
  }
  extend type Style {
    createdDate: String
  }
`;

const cache = new InMemoryCache({
  typePolicies: {
    // StyleConnection: {
    //   fields: {
    //     count: {
    //       read: (): number => 10,
    //     },
    //   },
    // },
    // Collection: {
    //   fields: {
    //     createdDate: {
    //       read: (): string => format(faker.date.past(), 'dd/MM/yyyy'),
    //     },
    //   },
    // },
    // Style: {
    //   fields: {
    //     createdDate: {
    //       read: (): string => format(faker.date.past(), 'dd/MM/yyyy'),
    //     },
    //   },
    // },
  },
});

interface GraphQlProviderProps {
  children: ReactNode;
}

export const GraphQlProvider = ({ children }: GraphQlProviderProps): ReactElement => {
  const { acquireIdToken } = useAuthentication();

  const buyingBranchesBE = useMemo(() => {
    const beBranches = {};
    [
      'basic-data-bff',
      'bom-bff',
      'collections-core',
      'order-bff',
      'styles-bff',
      'support',
      'user-bff',
    ].forEach((beBranchName) => {
      const branchNumberString = sessionStorage.getItem(`${beBranchName}BranchBE`);
      const branchNumber = branchNumberString ? JSON.parse(branchNumberString) : undefined;
      const branchSuffix = branchNumber ? `/BUY-${String(branchNumber)}` : '';
      beBranches[beBranchName] = branchSuffix;
    });
    return JSON.stringify(beBranches);
  }, []);

  const basicDataBranch = buyingBranchesBE ? JSON.parse(buyingBranchesBE)['basic-data-bff'] ?? '' : '';
  const orderBranch = buyingBranchesBE ? JSON.parse(buyingBranchesBE)['order-bff'] ?? '' : '';
  const stylesBranch = buyingBranchesBE ? JSON.parse(buyingBranchesBE)['styles-bff'] ?? '' : '';
  const userBranch = buyingBranchesBE ? JSON.parse(buyingBranchesBE)['user-bff'] ?? '' : '';
  const reportBranch = buyingBranchesBE ? JSON.parse(buyingBranchesBE)['report-bff'] ?? '' : '';

  const httpLinkBasicData = createHttpLink({
    uri: `${URLS.backendUrl}${String(basicDataBranch)}/basicdata-bff/graphql`,
    fetch,
  });
  const httpLinkOrder = createHttpLink({
    uri: `${URLS.backendUrl}${String(orderBranch)}/order-bff/graphql`,
    fetch,
  });
  const httpBFFLink = createHttpLink({
    uri: `${URLS.backendUrl}${String(stylesBranch)}/style-bff/graphql`,
    fetch,
  });
  const httpLinkUser = createHttpLink({
    uri: `${URLS.backendUrl}${String(userBranch)}/user-bff/graphql`,
    fetch,
  });
  const httpLinkReports = createHttpLink({
    uri: `${URLS.backendUrl}${String(reportBranch)}/report-bff/graphql`,
    fetch,
  });

  const authLink = useMemo(() => setContext(async (_, { headers }) => {
    // Fetch the token asynchronously
    const token = await acquireIdToken();

    return {
      headers: {
        ...headers,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Authorization: `Bearer ${token}`,
        // provider: 'AD',
      },
    };
  }), [acquireIdToken]);

  const apolloClient = useMemo(
    () =>
      new ApolloClient({
        link: authLink.concat(
          ApolloLink.split(
            (operation) => operation.getContext().clientName === GraphClientNames['bestone-bi4-buying-basic-data-bff'],
            httpLinkBasicData,
            ApolloLink.split(
              (operation) => operation.getContext().clientName === GraphClientNames['bestone-bi4-buying-order-bff'],
              httpLinkOrder,
              ApolloLink.split(
                (operation) => operation.getContext().clientName === GraphClientNames['bestone-bi4-buying-style-bff'],
                httpBFFLink,
                ApolloLink.split(
                  (operation) => operation.getContext().clientName === GraphClientNames['bestone-bi4-buying-user-bff'],
                  httpLinkUser,
                  ApolloLink.split(
                    (operation) => operation.getContext().clientName === GraphClientNames['bestone-bi2-buying-reports'],
                    httpLinkReports,
                  )
                )
              )
            )
          )
        ),
        cache,
        typeDefs,
      }),
    [authLink, httpBFFLink, httpLinkBasicData, httpLinkOrder, httpLinkReports, httpLinkUser]
  );

  return (
    <ApolloProvider client={apolloClient}>
      {children}
    </ApolloProvider>
  );
};
