import { useContext } from 'react';

import type { ProfilesContextType } from 'contexts/ProfilesContext/ProfilesContext';

import { ProfilesContext } from 'contexts/ProfilesContext/ProfilesContext';

export const useProfiles = (): ProfilesContextType => {
  const contextValue = useContext(ProfilesContext);
  if (!contextValue) throw new Error('useProfiles must be used within a ProfilesContext');
  return contextValue;
};
