/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable max-lines */
/* eslint-disable no-console */
import { useState, useEffect, useMemo } from 'react';

import type { Theme } from '@mui/material/styles';

import { EditIcon } from '@bestseller/bestone-buying-icon-library';
import axios from 'axios';

import { gql, useQuery } from '@apollo/client';
import { useIsAuthenticated } from '@azure/msal-react';
import { GraphClientNames } from '@bestseller/bestone-buying-ui-library';
import { useAuthentication } from '@bestseller-bit/frontend-community.utilities.authentication';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Box, Button, Chip, IconButton, TablePagination, TableRow, Tooltip, Typography, Dialog, DialogContent, DialogActions, DialogContentText, DialogTitle } from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { EditDialog } from 'components/EditDialog/EditDialog';
import { useProfiles } from 'hooks/useProfiles';
import { origin } from 'utils/origin.utils';

const StyledTableHeaders = styled(TableCell)(({ theme }: { theme: Theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const GET_USER_BY_NUMBER = gql`
  query GetUserByNumber($userNumber: UUID, $pagination: PaginationInput!) {
    users(userNumber: $userNumber, pagination: $pagination) {
      edges {
        node {
          userNumber
          fullName
          email
        }
      }
    }
  }
`;

const StyledTableRow = styled(TableRow)(({ theme }: { theme: Theme }) => ({
  '&:nth-of-type(odd)': {},
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: theme.palette.action.hover,
  },
}));

export interface CertificateData {
  certificateNumber: string;
  brandNumber: string;
  styleNumber: number;
  ceDocumentName: string;
  ceDocumentUrl: string;
  createdBy: string;
  changedBy: string;
  isDeleted: boolean;
  createdDate: string;
}

export interface BrandData {
  brandName: string;
  brandNumber: string;
  isDeleted: boolean;
}

interface FilteredTable {
  styleNumber: string;
  generalQuery: string;
  isSearchCleared: boolean;
  successMessage: string;
  selectedFileName: string | null;
  onCertificateCreated: () => void;
}

interface CreatedByUser {
  userNumber: string;
  fullName: string;
  email: string;
}

export const TableComponent = ({ styleNumber, generalQuery, isSearchCleared, successMessage, selectedFileName, onCertificateCreated }: FilteredTable) => {
  const [certificates, setCertificates] = useState<Array<CertificateData>>([]);
  const [brands, setBrands] = useState<Array<BrandData>>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const isAuthenticated = useIsAuthenticated();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [selectedCertificate, setSelectedCertificate] = useState<CertificateData | null>(null);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [latestCertificate, setLatestCertificate] = useState<CertificateData | null>(null);
  const { palette } = useTheme();
  const [isConfirmOpen, setConfirmOpen] = useState(false);
  const [certificateToDelete, setCertificateToDelete] = useState<CertificateData | null>(null);
  const [users, setUsers] = useState<Array<CreatedByUser>>([]);
  const { acquireIdToken } = useAuthentication();

  const [acquiredToken, setAcquiredToken] = useState<string | null>(null);

  useEffect(() => {
    acquireIdToken().then(token => {
      setAcquiredToken(token);
    });
  }, [acquireIdToken]);

  useEffect(() => {
    const fetchCertificates = async (): Promise<void> => {
      try {
          // Fetch certificates
          const certificatesResponse = await axios.get(`${origin}/safety-certificate/public/certificate/certificates`, {
            params: {
              // generalQuery,
              page: 0,
              size: 10000,
            },
          });
          setCertificates(certificatesResponse.data.certificates);

      } catch (error) {
        console.error('Certificates Error:', error);
      } finally {
        // Set isDataLoaded to true regardless of success or error
        setIsDataLoaded(true);
      }
    };
    fetchCertificates(); // Call the fetchCertificates function
  }, [onCertificateCreated]);

  useEffect(() => {
    const fetchBrands = async (): Promise<void> => {
      try {
          // Fetch brands
          const brandsResponse = await axios.get(`${origin}/safety-certificate/public/brand/brands`, {
          });
          setBrands(brandsResponse.data.brands);

      } catch (error) {
        console.error('Brands Error:', error);
      } finally {
        // Set isDataLoaded to true regardless of success or error
        setIsDataLoaded(true);
      }
    };

    fetchBrands(); // Call the fetchBrands function

  }, []);

  const handleChangePage = (event: unknown, newPage: number): void => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

const handleEditIconClick = (event: React.MouseEvent<HTMLButtonElement>, certificate: CertificateData): void => {
  setSelectedCertificate(certificate);
  setDialogOpen(true);
};

const filteredCertificates = styleNumber && !isSearchCleared
  ? certificates.filter((certificate) => certificate.styleNumber.toString().startsWith(styleNumber))
  : certificates;

const handleDeleteIconClick = async (event: React.MouseEvent<HTMLButtonElement>, certificate: CertificateData): Promise<void> => {
  try {
    const token = await acquireIdToken();
    await axios.delete(`${origin}/safety-certificate/private/certificate/${certificate.certificateNumber}`, {
      headers: {
        Authorization : 'Bearer ' + token,
      },
    });
    // Update the state to remove the deleted certificate
    setCertificates((prevCertificates) => prevCertificates.filter((c) => c.certificateNumber !== certificate.certificateNumber));
  } catch (error) {
    console.error('Error deleting certificate:', error);
  }
};

useEffect(() => {
  // Clear the latestCertificate state after 3000 milliseconds (adjust as needed)
  const timeoutId = setTimeout(() => {
    setLatestCertificate(null);
  }, 3000);

  // Clear the timeout when the component is unmounted or when a new certificate is added
  return () => void clearTimeout(timeoutId);
}, [latestCertificate]);

const userNumbersToFetch = useMemo(() => {
  // Put all unique user numbers in an array
  const allUserNumbers: Array<string> = certificates
    .flatMap((certificate) => [certificate.changedBy, certificate.createdBy])
    // Users can be unknown, in that case userNumber is null
    .filter((un) => !!un)
    // Filter out all users that we already have
    .filter((un) => !users.some((user) => user.userNumber === un));
  // Return an array with only unique userNumbers
  return [...new Set(allUserNumbers)];
}, [certificates, users]);

// Define a state to hold the user numbers
const [userNumbers, setUserNumbers] = useState(userNumbersToFetch);

  // If the token changes, reset the user numbers
  useEffect(() => {
    if (!acquiredToken) return;
    setUserNumbers(userNumbersToFetch);
  }, [acquiredToken, userNumbersToFetch]);

const { data } = useQuery(GET_USER_BY_NUMBER, {
variables: {
  userNumber: userNumbers[0], // Use the first user number from the state
  // userNumber: buyingProfile?.userNumber,
  pagination: {
    first: 1,
    after: '',
  },
},
skip: !userNumbers.length,
context: {
  clientName: GraphClientNames['bestone-bi4-buying-user-bff'],
  'Authorization': `Bearer ${acquiredToken}`,
},
});

useEffect(() => {
  if (data && data.users.edges.length > 0 && data.users.edges[0]) {
    const newUser = data.users.edges[0].node;
    setUsers((prevUsers) => [...prevUsers, newUser]);

    // Store the user data in local storage
    const storedUsers = JSON.parse(localStorage.getItem('users') || '[]');
    storedUsers.push(newUser);
    localStorage.setItem('users', JSON.stringify(storedUsers));

    setUserNumbers(userNumbers.slice(1));
  }
}, [data, userNumbers]);
const renderCertificateCreator = (certificate: CertificateData): string => {
let user: CreatedByUser | undefined = users.find((u) => u.userNumber === certificate.createdBy);
if (!user) {
  // Retrieve the user data from local storage
  const storedUsers: Array<CreatedByUser> = JSON.parse(localStorage.getItem('users') || '[]');
  user = storedUsers.find((storedUser) => storedUser.userNumber === certificate.createdBy);
}
return user ? user.fullName : 'User not found';
};

return (
  <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: 40,
        overflowX: 'hidden',
        overflowY: 'auto',
        backgroundColor: palette.mode === 'dark' ? palette.common.black : palette.common.white,
        flexGrow: 1,
      }}
    >
      {filteredCertificates.length === 0 && !styleNumber && (
        <Typography variant="body.default">
          No certificates are currently available.
        </Typography>
      )}
      {filteredCertificates.length === 0 && styleNumber && (
        <Typography variant="body.default">
          No certificate was found with this style number
          {' '}
          {styleNumber}
        </Typography>
      )}
      {filteredCertificates.length > 0 && (
        <>
          <TableContainer style={{ width: '100%', paddingLeft: '40px', backgroundColor: palette.mode === 'dark' ? palette.common.black : palette.common.white }}>
            <Table aria-label="safety-certificates table" style={{ alignItems: 'center', justifyContent: 'center', alignContent: 'center' }}>
              <TableHead>
                <TableRow>
                  <StyledTableHeaders align="left" style={{ width: isAuthenticated ? '20%' : '33%' }}>
                    <Typography variant="headings.h4" sx={{ color: palette.text.secondary }}>
                      Style Number
                    </Typography>
                  </StyledTableHeaders>
                  <StyledTableHeaders align="left" style={{ width: isAuthenticated ? '20%' : '33%' }}>
                    <Typography variant="headings.h4" sx={{ color: palette.text.secondary }}>
                      Brand Name
                    </Typography>
                  </StyledTableHeaders>
                  {isAuthenticated
                    && (
                      <StyledTableHeaders align="left">
                        <Typography variant="headings.h4" sx={{ color: palette.text.secondary }}>
                          Uploaded By
                        </Typography>
                      </StyledTableHeaders>
                    )}
                  <StyledTableHeaders align="left" style={{ width: isAuthenticated ? '20%' : '33%' }}>
                    <Typography variant="headings.h4" sx={{ color: palette.text.secondary }}>
                      CE-document
                    </Typography>
                  </StyledTableHeaders>
                  {isAuthenticated
                    && (
                      <StyledTableHeaders align="left">
                        <Typography variant="headings.h4" sx={{ color: palette.text.secondary }}>
                          Actions
                        </Typography>
                      </StyledTableHeaders>
                    )}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredCertificates
                  .sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime())
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((certificate) => (
                    <StyledTableRow
                      key={certificate.certificateNumber}
                      onClick={() => {
                        const link = document.createElement('a');
                        link.href = `${origin}/safety-certificate/public/certificate/certificatePdf/${certificate.certificateNumber}`;
                        link.target = '_blank';
                        link.download = certificate.ceDocumentName;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                      }}
                    >
                      <StyledTableHeaders align="left">
                        <Typography variant="body.default">
                          {certificate.styleNumber}
                        </Typography>
                        {latestCertificate && certificate.createdDate === latestCertificate.createdDate && (
                          <Chip label="New" color="success" variant="outlined" sx={{ marginLeft: '10px' }} />
                        )}
                      </StyledTableHeaders>
                      <StyledTableHeaders align="left">
                        <Typography variant="body.default">
                          {brands.find((brand) => brand.brandNumber === certificate.brandNumber)?.brandName ?? 'Brand Not Found'}
                        </Typography>
                      </StyledTableHeaders>
                      {isAuthenticated && (
                        <StyledTableHeaders align="left">
                          <Typography variant="body.default">
                            {renderCertificateCreator(certificate)}
                          </Typography>
                        </StyledTableHeaders>
                      )}
                      <StyledTableHeaders align="left">
                        <Box>
                          <Typography variant="body.default">
                            <span>{(selectedFileName ?? certificate.ceDocumentName).slice(0, 40)}</span>
                            {(selectedFileName ?? certificate.ceDocumentName).length > 40 && '...'}
                          </Typography>
                        </Box>
                      </StyledTableHeaders>
                      <StyledTableHeaders align='left' sx={{ paddingLeft: '0px' }}>
                        {isAuthenticated && (
                          <>
                            <Tooltip
                              title={<Typography fontSize={10} variant='buttons.xsmall'>Edit</Typography>}
                              placement="top"
                              slotProps={{
                                popper: {
                                  modifiers: [
                                    {
                                      name: 'offset',
                                      options: {
                                        offset: [0, -20],
                                      },
                                    },
                                  ],
                                },
                              }}
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    bgcolor: 'transparent',
                                    color: 'black',
                                  },
                                },
                              }}
                            >
                              <IconButton
                                disableFocusRipple
                                disableRipple
                                onClick={(event): void => {
                                  event.stopPropagation(); // Prevent the row's onClick from being triggered
                                  handleEditIconClick(event, certificate);
                                }}
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete" placement="top-start">
                              <IconButton
                                onClick={(event) => {
                                  event.stopPropagation(); // Prevent the row's onClick from being triggered
                                  setCertificateToDelete(certificate);
                                  setConfirmOpen(true);
                                }}
                              >
                                <DeleteOutlineOutlinedIcon />
                              </IconButton>
                            </Tooltip>

                          </>
                        )}
                      </StyledTableHeaders>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
            <Box display="flex" justifyContent="center" mt={2}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 15, 25, 50]}
                component="div"
                count={filteredCertificates.length ?? 0}
                rowsPerPage={rowsPerPage}
                page={page}
                labelRowsPerPage="Certificates per page"
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          </TableContainer>
          <Dialog
            open={isConfirmOpen}
            onClose={() => void setConfirmOpen(false)}
          >
            <DialogTitle style={{ textAlign: 'center' }}>
              Are you sure?
            </DialogTitle>
            <DialogContent>
              <DialogContentText style={{ textAlign: 'center' }}>
                This will permanently delete the certificate
              </DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
              <Button
                color="primary"
                onClick={(event) => {
                    event.stopPropagation(); // Prevent the row's onClick from being triggered
                    setConfirmOpen(false);
                }}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                onClick={(event) => {
                    event.stopPropagation(); // Prevent the row's onClick from being triggered
                    (async () => {
                      if (certificateToDelete) {
                        await handleDeleteIconClick(event, certificateToDelete);
                        setConfirmOpen(false);
                      }
                    }
                    )();
                }}
              >
                Delete
              </Button>
            </DialogActions>
          </Dialog>
          <EditDialog
            open={isDialogOpen}
            certificate={selectedCertificate}
            successMessage={successMessage}
            onClose={(): void => {
                setDialogOpen(false);
                setSelectedCertificate(null);
            }}
          />

        </>
            )}
    </Box>
  </div>
      );
    };
