import type { PropsWithChildren, ReactElement } from 'react';

import type {
  CircularProgressProps,
} from '@mui/material/CircularProgress';

import Box from '@mui/material/Box';
import MuiCircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
const PREFIX = 'CircularProgress';

const classes = {
  colorPrimary: `${PREFIX}-colorPrimary`,
};

const StyledBox = styled(Box)({
  [`& .${classes.colorPrimary}`]: {
    color: 'rgba(0, 0, 0, 0.25)',
  },
});

interface PropTypes extends CircularProgressProps {
  value: number;
}

export const CircularProgress = (
  props: PropsWithChildren<PropTypes>
): ReactElement => (
  <StyledBox position="relative" display="inline-flex">
    <MuiCircularProgress
      variant="determinate"
      value={props.value}
      thickness={4}
      classes={{ colorPrimary: classes.colorPrimary }}
    />
    <Box
      top={0}
      left={0}
      bottom={0}
      right={0}
      position="absolute"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {props.children}
    </Box>
  </StyledBox>
);
