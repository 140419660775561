const originUrl = window.location.origin;

const appUrl = '';

const branchUrl = new RegExp(/\/BUY-\d{4}/).exec(window.location.pathname)?.[0] ?? '';

const pageUrl = window.location.pathname
  .replace(appUrl, '')
  .replace(branchUrl, '');

const buyingRegEx = new RegExp(/buying(-dev|-test|).bestseller.com/i);

const ambasRegEx = new RegExp(
  /ambassador.buying01(-dev|-test|-prod).k8s.bestcorp.net/i
);

const backendUrl
  = buyingRegEx.test(originUrl) || ambasRegEx.test(originUrl)
    ? originUrl
    : 'https://ambassador.buying01-dev.k8s.bestcorp.net';

export const URLS = {
  backendUrl,
  originUrl,
  appUrl,
  branchUrl,
  pageUrl,
  generate: {
    masterUrl: (page = '/collections'): string =>
      `${originUrl}${appUrl}${page}`,
    fullUrl: (page = '/collections'): string =>
      `${originUrl}${appUrl}${branchUrl}${page}`,
  },
};
