import type { ReactElement } from 'react';
import { useMemo, useState, useEffect } from 'react';

import { merge } from 'lodash';

import { THEME_NAMES } from '@bestseller/bestone-buying-ui-library';
import { MUI_THEME_DARK, MUI_THEME_LIGHT } from '@bestseller/design-system';
import { AuthenticationWrapper } from '@bestseller-bit/frontend-community.utilities.authentication';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Header } from 'components/Header/Header';
import { TableComponent } from 'components/TableComponent/TableComponent';
import { THEME_EXTENSION } from 'constants/themeExtension';
import { GraphQlProvider } from 'providers/GraphQlProvider/GraphQlProvider';
import { ProfilesProvider } from 'providers/ProfilesProvider/ProfilesProvider';
import { useLocalStorage } from 'usehooks-ts';

const App = (): ReactElement => {
    // NOTE: check device default theme
    const isDarkSystem = window.matchMedia(
      '(prefers-color-scheme: dark)'
    ).matches;

  const [localThemeName, setLocalThemeName] = useLocalStorage(
    'buyingThemeName',
    String(isDarkSystem
      ? THEME_NAMES.dark
      : THEME_NAMES.light
    )
  );

  const initialThemeOptions = useMemo(
    () => localThemeName === THEME_NAMES.light
      ? merge(MUI_THEME_LIGHT, THEME_EXTENSION)
      : merge(MUI_THEME_DARK, THEME_EXTENSION),
    [localThemeName]
  );

  const initialTheme = useMemo(() => createTheme(initialThemeOptions), [initialThemeOptions]);

  const [theme, setTheme] = useState(initialTheme);
  //state that manages the styleNumber as string
  //I use the styleNumber to filter the table in the parent component
  const [styleNumber, setStyleNumber] = useState<string>('');
  const [generalQuery, setGeneralQuery] = useState<string>('');
  const [isSearchCleared, setSearchCleared] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [selectedFileName, setSelectedFileName] = useState<string | null>(null);
  const [refreshTable, setRefreshTable] = useState(false);

  const handleSearchClear = () => {
    setSearchCleared(true);
    setStyleNumber(''); // Clear the styleNumber state
  };

  // reset isSearchCleared when styleNumber changes
  useEffect(() => {
    if (styleNumber !== '') {
      setSearchCleared(false);
    }
  }, [styleNumber]);

  const refreshCertificates = () => {
    setRefreshTable(prevState => !prevState);
  };

  return (
    <AuthenticationWrapper
      clientId={`${process.env.REACT_APP_AZURE_CLIENT_ID}`}
      tenantId={`${process.env.REACT_APP_AZURE_TENANT_ID}`}
      preventAutomaticLogin
    >
      {/* Get MSAL and Buying profiles */}
      <GraphQlProvider>
        <ProfilesProvider>
          <ThemeProvider theme={theme}>
            {/* <BuyingThemeProvider> */}
            <Header
              styleNumber={styleNumber}
              setStyleNumber={setStyleNumber}
              generalQuery={generalQuery}
              setGeneralQuery={setGeneralQuery}
              successMessage={successMessage}
              onSearchClear={handleSearchClear}
              onCertificateCreated={refreshCertificates}
            />
            <TableComponent
              styleNumber={styleNumber}
              generalQuery={generalQuery}
              isSearchCleared={isSearchCleared}
              successMessage={successMessage}
              selectedFileName={selectedFileName}
              onCertificateCreated={refreshCertificates}
            />
            {/* </BuyingThemeProvider> */}
          </ThemeProvider>
        </ProfilesProvider>
      </GraphQlProvider>
    </AuthenticationWrapper>
  );
};

export default App;
