/* eslint-disable max-lines */
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { ReactElement, ReactNode } from 'react';

import type { Toast } from 'react-hot-toast';

import {
  CircleCheckIcon,
  CircleExclamationIcon,
  CircleInfoIcon,
  WarningIcon,
} from '@bestseller/bestone-buying-icon-library';

import { useAuthentication } from '@bestseller-bit/frontend-community.utilities.authentication';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import toastEl from 'react-hot-toast';

import { DismissButton } from './DismissButton';
import { UndoButton } from './UndoButton';

enum ToastMessageType {
  error = 'error',
  info = 'info',
  success = 'success',
  warning = 'warning',
}

const PREFIX = 'Message';

const classes = {
  container: `${PREFIX}-container`,
  icon: `${PREFIX}-icon`,
  message: `${PREFIX}-message`,
  title: `${PREFIX}-title`,
  description: `${PREFIX}-description`,
  actions: `${PREFIX}-actions`,
};

interface StyledProps {
  type: ToastMessageType;
  customColor?: string | Array<string>;
  customIcon?: ReactNode;
}

const Root = styled('div')<StyledProps>((
  {
    theme: { palette, spacing },
    type,
    customColor,
  }
) => {
  const borderWidth = 2;
  let gradient = '';
  let borderColor = palette[type].main;
  let iconColor = palette[type].main;
  if (customColor) {
    if (Array.isArray(customColor)) {
      const colorMap = customColor.map((c, i) => {
        const startVal = i * 10;
        const endVal = i + 1 * 10;
        return `${c} ${startVal}px, ${c} ${endVal}px`;
      });
      gradient = `
        repeating-linear-gradient(
          45deg,
          ${colorMap.join(', ')}
        )
      `;
      borderColor = 'transparent';
      iconColor = customColor[0];
    } else {
      borderColor = customColor;
      iconColor = customColor;
    }
  }

  return {
    [`&.${classes.container}`]: {
      display: 'flex',
      borderWidth: `${borderWidth}px`,
      borderStyle: 'solid',
      borderColor,
      borderRadius: spacing(4),
      boxShadow: '0 3px 10px rgba(0, 0, 0, 0.1), 0 9px 28px 8px rgba(63, 62, 62, .05)',
      padding: spacing(4),
      margin: spacing(2),
      background: palette.background.paper,
      color: '#FFF',
      border: `solid ${borderWidth}px ${borderColor}`,
      backgroundClip: 'padding-box',
      ['&:before']: {
        content: '""',
        position: 'absolute',
        top: 14,
        bottom: 14,
        right: 20,
        left: 20,
        zIndex: -1,
        margin: `-${borderWidth}px`,
        borderRadius: 'inherit',
        background: gradient,
      },
    },
    [`& .${classes.icon}`]: {
      margin: spacing('auto', 4, 'auto', 0),
      color: iconColor,
    },
    [`& .${classes.message}`]: {
      display: 'flex',
      flexDirection: 'column',
    },

    [`& .${classes.title}`]: {
      fontSize: 16,
      fontWeight: 600,
      color: palette.text.primary,
    },

    [`& .${classes.description}`]: {
      fontSize: 14,
      fontWeight: 400,
      marginTop: '.25rem',
      color: palette.text.secondary,
    },

    [`& .${classes.actions}`]: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      paddingLeft: '2rem',
    },
  };
});

const icons = {
  success: <CircleCheckIcon className={classes.icon} />,
  error: <CircleExclamationIcon className={classes.icon} />,
  warning: <WarningIcon className={classes.icon} />,
  info: <CircleInfoIcon className={classes.icon} />,
};

interface PropTypes {
  t: Toast;
  type: ToastMessageType;
  title: string;
  description: string;
  duration: number;
  color?: string | Array<string>;
  icon?: ReactNode;
  showTimer?: boolean;
  showUndo?: boolean;
  onDismiss?: () => void;
  onUndo?: () => void;
  action?: string;
}

const Message = ({
  t,
  type,
  title,
  description,
  duration,
  color,
  icon,
  showTimer = false,
  showUndo = false,
  onDismiss,
  onUndo,
  action,
}: PropTypes): ReactElement => {
  const [complete, setComplete] = useState(false);
  const [passed, setPassed] = useState(0);
  const { login } = useAuthentication();

  const shortDuration = useMemo(() => duration - 500, [duration]);

  const removeToast = useCallback(() => {
    toastEl.dismiss(t.id);
    setTimeout(() => {
      toastEl.remove(t.id);
    }, 500);
  }, [t.id]);

  const handleDismiss = useCallback(() => {
    onDismiss?.();
    removeToast();
  }, [removeToast, onDismiss]);

  const handleUndo = useCallback(() => {
    onUndo?.();
    removeToast();
  }, [removeToast, onUndo]);

  const interval = useMemo(
    () =>
      setInterval(() => {
        if (passed <= shortDuration) {
          setPassed((value: number) => value + 100);
        } else if (!complete && passed >= shortDuration) {
          onDismiss?.();
          setComplete(true);
          setPassed(shortDuration);
        }
      }, 100),
    [complete, passed, shortDuration, onDismiss]
  );

  useEffect(() => (): void => {
    clearInterval(interval);
  }, [interval]);

  const handleRetryLogin = useCallback((): void => {
    login?.();
    removeToast();
  }, [login, removeToast]);

  return (
    <Root className={classes.container} type={type} customColor={color}>
      {icon ?? icons[type]}
      <div className={classes.message}>
        <span className={classes.title}>{title}</span>
        <span className={classes.description}>{description}</span>
      </div>
      <div className={classes.actions}>
        {showUndo ? (
          <UndoButton
            timerValue={passed / shortDuration * 100}
            onUndo={handleUndo}
          />
        ) : null}
        {action === 'login' ? <Button variant='outlined' color='warning' onClick={handleRetryLogin}>Log me in</Button> : (
          <DismissButton
            timerValue={passed / shortDuration * 100}
            showTimer={showTimer}
            onDismiss={handleDismiss}
          />
        )}
      </div>
    </Root>
  );
};

export { Message, ToastMessageType };
