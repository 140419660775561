/* eslint-disable @typescript-eslint/naming-convention */
import type { ThemeOptions } from '@mui/material/styles';

export const THEME_EXTENSION: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 700,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  // NOTE: Use this for overwrites to the local theme
  components: {},
};
