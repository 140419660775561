import { createContext } from 'react';

import type { BuyingProfile, MsalProfile } from '@bestseller/bestone-buying-ui-library';

interface ProfilesContextType {
  msalProfile: MsalProfile | undefined;
  setMsalProfile: (msalProfile: MsalProfile | undefined) => void;
  buyingProfile: BuyingProfile | undefined;
  setBuyingProfile: (buyingProfile: BuyingProfile | undefined) => void;
  loading: boolean;
}

const ProfilesContext = createContext<ProfilesContextType | null>(null);

export { ProfilesContext };
export type { ProfilesContextType };
